.App {
}

.page {
  position: "absolute";
  width: "100%";
  background-color: "#ececec";
  min-height: '100vh';
}
.page-enter {
  opacity: 1;
  transform: scale(1);
  transform: translate(0px, 150px);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transform: translate(0px, 0px);  
  transition: opacity 300ms, transform 300ms;
  /*transition-timing-function: ease-in;*/
}

.page-exit {
  opacity: 1;
  transform: scale(1);
  transform: translate(0px, 0px);
}

.page-exit-active {
  opacity: 0;
  /*transform: scale(0.9);*/
  /*transform: translate(0px, 100px);*/
  /*transition-timing-function: ease-out;  */
  transition: opacity 300ms, transform 300ms;
}

.sidebar-fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.MuiChip-root{
  height: 26px !important;
}

.yarl__thumbnails_thumbnail_active {
  border-color: #FF6F00 !important;
}

.rbc-today {
  background-color: #f7f7e6 !important;
}

.rbc-toolbar-label {
  font-weight: bold !important;
}

.rbc-button-link.rbc-show-more {
  color: #ff6f00 !important;
}

.rbc-show-more {
  color: #ff6f00 !important;
  cursor: pointer !important;
  font-size: 0.8rem !important;
  background-color: transparent !important;
}

.rbc-event {
  padding: 0 !important;
  padding-right: 5px !important;
}

.rbc-row-segment {
  padding: 0 2px 0 1px !important;
}

.custom-event {
  transition: background-color 0.3s ease, color 0.3s ease !important;
}

.custom-event:hover {
  background-color: #f7f7e6 !important;
}

@media (max-width: 600px) {
  .rbc-toolbar-label {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}